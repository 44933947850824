import * as GUI from "babylonjs-gui";
export class Connection{  

    constructor(parsedJSONConnection, appManager, widgetManager){
        this.appManager = appManager;
        this.connectionName = parsedJSONConnection.connectionName;
        this.displayText = parsedJSONConnection.displayText || null;
        this.focusThisConnection = parsedJSONConnection.focusThisConnection || false;
        this.isHallway = parsedJSONConnection.isHallway || false;
        this.arrowDirection = parsedJSONConnection.arrowDirection || "straight";
        this.icon = null;
        this.rotationX = parsedJSONConnection.rotationX;
        this.rotationY = parsedJSONConnection.rotationY;
        this.widgetButton = null;
        this.widgetButtonMesh = null;
        this.widgetManager = widgetManager;
    }

    createWidgetButton(){
        var buttonPlane = BABYLON.MeshBuilder.CreatePlane("card", {width:1.2, height:1}, this.scene);
        var buttonADT = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(buttonPlane);

        var button1 = BABYLON.GUI.Button.CreateSimpleButton("but1", "");
        button1.width = "1100px"
        button1.height = "1100px";
        button1.background = getComputedStyle(document.documentElement).getPropertyValue('--color-faded-black-25');
        button1.cornerRadius = 100000;
        button1.thickness = 10;
        button1.color = getComputedStyle(document.documentElement).getPropertyValue('--color-highlight');
        button1.zIndex = 10;
        button1.paddingBottomInPixels = 270;
        button1.paddingTopInPixels = 250;
        button1.paddingLeftInPixels = 250;
        button1.paddingRightInPixels = 250;
        button1.clipChildren = false;
        button1.clipContent = false;
        button1.shadowBlur = 150;

        if(this.displayText != null)
            var text = new BABYLON.GUI.TextBlock("text",this.displayText);
        else
            var text = new BABYLON.GUI.TextBlock("text","Go to "+this.appManager.spaces[this.connectionName-1].name);
        text.fontSizeInPixels = 140;
        text.color = "white";
        //text.paddingTopInPixels = 800;
        text.clipContent = false;
        text.scaleX = 0.7;
        text.shadowBlur = 35;
        text.textWrapping = BABYLON.GUI.TextWrapping.WordWrap;
        text.resizeToFit = true;
        button1.addControl(text);

        var imge = new advImage("string", "./textures/ConnectionArrow.png");
        if(this.arrowDirection == "left"){
            imge.scaleY = 0.85;
            imge.leftInPixels = -340;
            imge.topInPixels = -100;
            imge.rotation = 5;
        }else if(this.arrowDirection == "right"){
            imge.scaleY = 0.85;
            imge.leftInPixels = 340;
            imge.topInPixels = -100;
            imge.rotation = 1.3;
        }else{
            imge.topInPixels = -350;
            imge.scaleY = 0.9;
        }
        imge.shadowBlur = 75;
        imge.onImageLoadedObservable.addOnce(function(){
            imge.recolor(1, 0, 0.8);
        })
        button1.addControl(imge); 
        buttonADT.addControl(button1);
        this.widgetButtonMesh = buttonPlane;
        this.widgetButton = button1;

        this.setWidgetLocation();
    }

    setWidgetLocation(){
        this.widgetButtonMesh.position.z = 8;
        var widgetPivot = BABYLON.MeshBuilder.CreateSphere("sphere", {}, this.appManager.scene);
        widgetPivot.isVisible = false;
        widgetPivot.position = new BABYLON.Vector3(0, 0, 0);
        this.widgetButtonMesh.parent = widgetPivot;
        widgetPivot.rotation.x += Math.PI * (this.rotationX*0.1);
        widgetPivot.rotation.y += Math.PI * (this.rotationY*0.1);

        this.setUpAction();
    }

    setUpAction(){
        this.widgetButton.onPointerClickObservable.add(fun => {
            this.appManager.updateSpace(this.connectionName);
            this.appManager.cameraManager.lookAtMesh(this.widgetButtonMesh);
        });
        this.widgetButton.pointerEnterAnimation = () => {
            this.setWidgetScale("hover");
        }
        this.widgetButton.pointerOutAnimation = () => {
            this.setWidgetScale("unhover");
        }
        if(this.focusThisConnection){
            this.appManager.cameraManager.lookAtMeshSlowly(this.widgetButtonMesh);
        }
    }

    setWidgetScale(value){
        if(value == "moving")
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(0.7,0.7,0.7)
        else if(value == "hover" && this.widgetButtonMesh.scaling.x != 0.7)
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1.5,1.5,1.5)
        else if(value == "unhover" && this.widgetButtonMesh.scaling.x != 0.7)
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1,1,1)
        else if(value != "moving" && value != "hover" && value != "unhover")
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1,1,1)
    }

    dispose(){
        this.widgetButton.onPointerClickObservable.clear();
        this.widgetButtonMesh.dispose();
    }
}
class advImage extends BABYLON.GUI.Image {
    constructor(){
        super(...arguments);
        let _t = this;

        this.__canvas = document.createElement('canvas');

        this.onImageLoadedObservable.add(_t.initAfterLoad.bind(_t));
    }
    initAfterLoad(){
        let _t = this;
        let domImg = _t.domImage;
        _t.__canvas.width = domImg.width;
        _t.__canvas.height = domImg.height;
    }
    recolor( red = 1, green = 1, blue = 1){
      //  if(!this.isLoaded) return;

        let _t = this;
        let context = _t.__canvas.getContext('2d');

        context.drawImage(_t.domImage, 0, 0);

        let imgData = context.getImageData(0, 0, _t.__canvas.width, _t.__canvas.height);
        
        for (var i = 0; i < imgData.data.length; i+=4) {
            imgData.data[i] *= red;
            imgData.data[i+1] *= green;
            imgData.data[i+2] *= blue;
            //imgData.data[i+3] *= 1; // ALPHA
        }

        context.putImageData(imgData, 0, 0);

        let dataUrl = _t.__canvas.toDataURL("image/jpg");

        _t.source = dataUrl;
        _t._markAsDirty(true);

        return _t;
    }
}