import { Connection } from "./connection.model";
import { Widget } from "./widgetUpdated.model";


export class Space{  

    constructor(parsedJSONSpace, appManager, widgetManager){
        this.appManager = appManager;
        this.id = parsedJSONSpace.id;
        this.textureURL = parsedJSONSpace.textureURL;
        this.isHighlighted = parsedJSONSpace.isHighlighted;
        this.name = parsedJSONSpace.name;
        this.widgetManager = widgetManager;
        this.connections = [];
        for (var i = 0; i < parsedJSONSpace.connections.length; i++) {
            this.connections.push(
                new Connection(
                    parsedJSONSpace.connections[i],
                    this.appManager,
                    this.widgetManager
                )
            )
        }
        this.dome = null;
        this.widgets = [];
        for (var i = 0; i < parsedJSONSpace.widgets.length; i++) {
            this.widgets.push(
                new Widget(
                    parsedJSONSpace.widgets[i],
                    this.appManager,
                    this.widgetManager
                )
            )
        }
        this.texture = null;
    }

    disposeSpace(){
        this.dome.dispose();
        for (var i = 0; i < this.connections.length; i++) {
            this.connections[i].dispose();
        }
        for (var i = 0; i < this.widgets.length; i++) {
            this.widgets[i].dispose();
        }
    }

    createDome(){
        if(this.textureURL =="./textures/video.mp4"){
            this.dome = new BABYLON.VideoDome(
                "Dome_"+this.id+"_"+this.name,
                this.textureURL,
                {
                    resolution: 128,
                    size: 100,
                    useDirectMapping: false
                },
                this.appManager.scene
            );
        }else{
            this.dome = new BABYLON.PhotoDome(
                "Dome_"+this.id+"_"+this.name,
                this.textureURL,
                {
                    resolution: 128,
                    size: 100,
                    useDirectMapping: false
                },
                this.appManager.scene
            );
        }
        this.dome.texture.onLoadObservable.add(fun => {
            this.appManager.disposeLastSpace();
            for (var i = 0; i < this.connections.length; i++) {
                this.connections[i].createWidgetButton();
            }
            for (var i = 0; i < this.widgets.length; i++) {
                this.widgets[i].createWidgetButton();
            }
        });
    } 

    getRandomConnection(){
        var randomConnection = this.connections[0];
        if(this.connections.length > 1){
            randomConnection = this.connections[this.randomNumber(0, this.connections.length)];
            this.checkIfSameSpace(randomConnection);
        }else{
            this.jumpToNewSpace(randomConnection);
        }    
    }

    checkIfSameSpace(randomConnection){
        for (var i = 0; i < this.appManager.spaces.length; i++) {
            if(this.appManager.spaces[i].id == randomConnection.connectionName){
                var desiredSpace = i;
            }
        }
        if(desiredSpace == this.appManager.previousSpace){
            this.getRandomConnection();
        }else{
            if(randomConnection.widgetButtonMesh != null)
                this.jumpToNewSpace(randomConnection);
        }
    }

    jumpToNewSpace(randomConnection){
        var self = this;
        this.appManager.introBot.startBot();
        if(this.appManager.introBot.isRunning == true && this.appManager.timerNewSpace == null){
            this.appManager.cameraManager.lookAtMeshSlowly(randomConnection.widgetButtonMesh);
            this.appManager.timerNewSpace = setTimeout(function() {
                if(self.appManager.introBot.isRunning == true){
                    self.appManager.updateSpace(randomConnection.connectionName);
                }
            }, 4000);
        }
    }

    randomNumber(min, max){
        const r = Math.random()*(max-min) + min
        return Math.floor(r)
    }

    setWidgetSizes(isSmall){
        for (var i = 0; i < this.widgets.length; i++) {
            if(isSmall && this.widgets[i].widgetButtonMesh){
                this.widgets[i].setWidgetScale("moving");
            }else if(!isSmall && this.widgets[i].widgetButtonMesh){
                this.widgets[i].setWidgetScale("");
            }
        }
        for (var i = 0; i < this.connections.length; i++) {
            if(isSmall && this.connections[i].widgetButtonMesh){
                this.connections[i].setWidgetScale("moving");
            }else if(!isSmall && this.connections[i].widgetButtonMesh){
                this.connections[i].setWidgetScale("");
            }
        }
    }
}