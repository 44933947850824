import { Space } from "./space.model";
import { Map } from "./map.model";
import data from "./spaces02.json"
import * as GUI from "babylonjs-gui";
import { IntroBot } from "./introBot";

export class AppManager{  

    constructor(scene){
        this.parsedJSON = data;//JSON.parse(data);
        this.scene = scene;
        this.engine = null;
        this.camera = null;
        this.cameraManager;
        this.introBot = null;
        this.dome = null;
        this.map = null;
        this.spaces = [];
        this.nextSpaces = [];
        this.currentSpace = 0;
        this.previousSpace = null;
        this.is3DModelRendered = false;
        this.isLoadingSpace = false;
        this.timerNewSpace = null;

        this.createSpaces();
        this.setUpTitles();
    }

    updateSpace(spaceID){
        if(!this.isLoadingSpace && spaceID != this.spaces[this.currentSpace].id){
            this.isLoadingSpace = true;
            this.cameraManager.transitionOutEffect();
            this.previousSpace = this.currentSpace;
            for (var i = 0; i < this.spaces.length; i++) {
                if(this.spaces[i].id == spaceID){
                    this.currentSpace = i;
                    this.spaces[this.currentSpace].createDome();
                    document.getElementById("dropdownbutton").innerHTML = this.spaces[this.currentSpace].name;
                    this.timerNewSpace = null;
                }
            }
            this.map.updateLocationOnMap(spaceID);
        }
    }
    updateSpaceByName(spaceName){
        if(!this.isLoadingSpace && spaceName != this.spaces[this.currentSpace].name){
            this.isLoadingSpace = true;
            this.cameraManager.transitionOutEffect();
            this.previousSpace = this.currentSpace;
            for (var i = 0; i < this.spaces.length; i++) {
                if(this.spaces[i].name == spaceName){
                    this.currentSpace = i;
                    this.spaces[this.currentSpace].createDome();
                    document.getElementById("dropdownbutton").innerHTML = this.spaces[this.currentSpace].name;
                    this.timerNewSpace = null;
                    this.map.updateLocationOnMap(i+1)
                    break;
                }
            }
        }
    }

    disposeLastSpace(){
        if(this.previousSpace != null){
            this.cameraManager.transitionInEffect();
            this.spaces[this.previousSpace].disposeSpace(); 
        }
        else
        {
            this.introBot = new IntroBot(this);
        }
    }

    createSpaces(){
        var dropdown = document.getElementById("jumptomenu");
        var widgetManager = new GUI.GUI3DManager(this.scene);
        var roomIsAdded = false;
        for (var i = 0; i < this.parsedJSON.spaces.length; i++) {
            if(this.parsedJSON.spaces[i].isHighlighted){
                var optn = document.createElement("a");
                optn.text = this.parsedJSON.spaces[i].name;
                dropdown.appendChild(optn);  
                optn.addEventListener("click", (event) => 
                    this.updateSpaceByName(event.toElement.innerHTML)); 
            }
            this.spaces.push(
                new Space(
                    this.parsedJSON.spaces[i], 
                    this,
                    widgetManager
                )  
            )    
        }
        this.spaces[0].createDome();
        this.map = new Map(this) 
        document.getElementById("dropdownbutton").innerHTML = this.spaces[this.currentSpace].name;
    }

    setUpTitles(){
        var elemtitlebox = document.getElementById("title_box");
        var elemTitle = document.getElementById("title");
        elemTitle.innerHTML = this.parsedJSON.title;
        var elemSubtitle = document.getElementById("subtitle");
        elemSubtitle.innerHTML = this.parsedJSON.subtitle;
        var elemButton = document.getElementById("button_start");
        var elemHelper = document.getElementById("helper");
        var self = this;
        var destroyBot = function (){
            self.map.updateLocationOnMap(1);
            elemSubtitle.style.display = "none";
            elemTitle.style.display = "none";
            elemButton.style.display = "none";
            elemtitlebox.style.display = "none";
            var dropdown = document.getElementById("dropdownbutton");
            dropdown.style.display = "block";
            var dropdown = document.getElementById("mapbtn");
            dropdown.style.display = "block";
            var dropdown = document.getElementById("mapButton");
            dropdown.style.display = "block";
            elemHelper.style.display = "block";
            self.introBot.disposeBot();
            setTimeout(function() {
                elemHelper.style.display = "none";

            }, 5000);
        }

        elemButton.addEventListener("mouseup", destroyBot, false);
        elemButton.addEventListener("touchend", destroyBot, false);
    }
}