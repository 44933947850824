import { PanelComponent } from "./panelComponent.model";

export class PanelGroup{  

    constructor(widgetGroup, content, type, appManager){
        this.appManager = appManager;
        this.content = content;
        this.type = type;
        this.widgetGroup = widgetGroup || null;
        this.panels = [];
        this.myFunction = this.hideModal;

        this.createPanel();
    }

    createPanel(){
        var modal = document.getElementById("myModal");
        var blackout = document.getElementById("blackout");
        modal.style.display = "block";
        blackout.style.display = "block";

        blackout.addEventListener("touchend", this.hideModal);
        blackout.addEventListener("click", this.hideModal);

        this.fillPanel();
    }

    fillPanel(){
        if(this.widgetGroup != null)
            this.widgetGroup.forEach(element => addComponent(element));   
        else  
            new PanelComponent(this.type, this.content);
        
        function addComponent(element) {
            new PanelComponent(element.type, element.content);
        }
    }

    hideModal() {
        document.getElementById("myModal").style.display = "none";
        document.getElementById("blackout").style.display = "none";

        var elements = document.getElementsByClassName("modal-content");
        while(elements[0] != null)
            elements[0].remove();

        document.getElementById("blackout").removeEventListener('click', this.hideModal);
        document.getElementById("blackout").removeEventListener('touchend', this.hideModal);
    }
}