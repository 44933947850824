
export class PanelComponent{  

    constructor(type, content){
        this.type = type;
        this.content = content;
        this.div = null;

        this.createComponent();
    }

    createComponent(){
        const currentDiv = document.getElementById("myModal");
        this.div = document.createElement("div");
        this.div.setAttribute("class", "modal-content");

        currentDiv.appendChild(this.div); 
        this.loadContent();       
    }

    loadContent(){
        switch (this.type) {
            case "video":
                this.setUpVideo(); break;
            case "form":
                this.setUpVideo(); break;
            case "image":
                this.setUpImage(); break;
            case "text":
                this.setUpText(); break;
            case "3d":
                this.setUp3D(); break;
            case "form":
                this.setUpText(); break;
            case "audio":
                this.setUpAudio(); break;
            case "website":
                this.setUpText(); break;
            case "livedata":
                this.setUpLiveData(); break;
        }
    }

    setUpVideo(){
        var ifrm = document.createElement("iframe");
        ifrm.setAttribute("src", this.content);
        ifrm.style.width = "100%";
        ifrm.style.height = "calc(100px + 25vw)";
        ifrm.style.border = "none";

        this.div.appendChild(ifrm);
    }

    setUpImage(){
        var img = document.createElement("img");
        img.setAttribute("src", this.content);
        img.style.height = "auto";
        img.style.width = "100%";
        img.style.border = "none";

        this.div.appendChild(img);
    }

    setUpAudio(){
        var audio = document.createElement("AUDIO");
        audio.setAttribute("src", this.content);
        audio.setAttribute("controls", "controls");
        audio.style.width = "100%";
        audio.style.height = "5vw";
        audio.style.border = "none";
        this.div.appendChild(audio);
    }

    setUpText(){
        var title, rest;
        var contentArr = this.content.split('-');
        if(contentArr.length > 1){
            title = contentArr[0];
            rest = contentArr[1];
            var divTitle = document.createElement("div");
            divTitle.appendChild(document.createTextNode(title));
            this.div.appendChild(divTitle);
            divTitle.setAttribute("class", "text-title");
        }
        else 
            rest = contentArr[0];
            
        var divText = document.createElement("div");
        divText.appendChild(document.createTextNode(rest));
        this.div.appendChild(divText);
    }

    setUpLiveData(){
        var content2 = null;
        var newContent;
        fetch(this.content)
            .then(response => response.json())
            .then(json => content2 = "Current temp: " + json.current.temp_c)
            .then(content2 => newContent = document.createTextNode(content2))
            .then(newContent => this.div.appendChild(newContent))
    } 

    disposeComponent(){
        // dispose all of the components
        //this.div = null;
        /* var temp = document.getElementsByClassName("modal-content");
        temp = null; */
    }
}