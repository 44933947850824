// #region Imports
import './styles.css';
import * as BABYLON from "babylonjs";
import 'babylonjs-loaders';
import { AppManager } from "./Models/appManager.model";
import { CameraManager } from "./Models/cameraManager.model";
// #endregion

var canvas = document.getElementById("renderCanvas");
var engine, scene, appManager;
var isMoving = false;
var hasStopped = true;
var isEqual = undefined;
var current_cam_pos = new BABYLON.Vector3.Zero();
let divFps = document.getElementById("fps");
document.addEventListener("DOMContentLoaded", startGame);

async function startGame(){
    engine = new BABYLON.Engine(canvas,true);
    scene = await createScene();
    /* var innerArrow = document.getElementById("triangle"); */
    engine.runRenderLoop(function (){
        scene.render();
        ////divFps.innerHTML = engine.getFps().toFixed() + " fps";     
        
    }); 
    scene.registerBeforeRender(function update(){
        if(appManager.spaces[appManager.currentSpace].widgets != null){
            if(!isEqual)
                isEqual = equalWithTolerance(current_cam_pos,appManager.camera.absoluteRotation, 0)
            else
                isEqual = equalWithTolerance(current_cam_pos,appManager.camera.absoluteRotation, 0.001)
            if (!isEqual && !isMoving) { 
                isMoving = true;
                isEqual = false;         
                current_cam_pos = new BABYLON.Vector3(appManager.camera.absoluteRotation.x,appManager.camera.absoluteRotation.y,appManager.camera.absoluteRotation.z)
                appManager.spaces[appManager.currentSpace].setWidgetSizes(true);
            } else {
                if(isEqual && isMoving) {
                    isMoving = false; 
                    appManager.spaces[appManager.currentSpace].setWidgetSizes(false);
                    //innerArrow.setAttribute('transform',"translate(30,40)");
                    //innerArrow.style.transform ='rotate('+((appManager.camera.absoluteRotation.y-current_cam_pos.y)*10)+'deg)';
                    //console.log(((appManager.camera.absoluteRotation.y-current_cam_pos.y)*10))
                }else{
                    current_cam_pos = new BABYLON.Vector3(appManager.camera.absoluteRotation.x,appManager.camera.absoluteRotation.y,appManager.camera.absoluteRotation.z)
                }
            }
        }    
    }); 
}

function equalWithTolerance(a, b, tolerance){
    if ((Math.abs(a.x - b.x)+Math.abs(a.y - b.y)+Math.abs(a.z - b.z)) <= tolerance)
        return true;
    else
        return false;
}

var createScene = async function () {
    var scene = new BABYLON.Scene(engine);
    appManager = new AppManager(scene);
    appManager.engine = engine;
    appManager.cameraManager = new CameraManager(appManager);
    appManager.camera = appManager.cameraManager.camera;

    return scene;
}

window.addEventListener("resize", function () {
    engine.resize();
});