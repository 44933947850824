export class IntroBot{  

    constructor(appManager){
        this.appManager = appManager;
        this.spaces = [];
        this.currentSpace = 0;
        this.timeDelay = 5500;
        this.isRunning = false;

        this.prepareBot();
    }

    prepareBot(){
        this.isRunning = true;
        this.startBot();
    }

    startBot(){
        var self = this;
        if(this.isRunning == true){
            setTimeout(function() {
                if(self.appManager.introBot.isRunning == true){
                    self.appManager.spaces[self.appManager.currentSpace].getRandomConnection();
                }
            }, this.timeDelay);
        }
    }

    disposeBot(){
        this.isRunning = false;
        clearTimeout(this.appManager.timerNewSpace);
        this.appManager.timerNewSpace = null;
        if(this.appManager.currentSpace != 0)
            this.appManager.updateSpaceByName(this.appManager.spaces[0].name)
    }
}