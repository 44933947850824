import { PanelGroup } from "./panelGroup.model";

export class Widget{  

    constructor(parsedJSONWidget, appManager, widgetManager){
        this.parsedJSONWidget = parsedJSONWidget;
        this.appManager = appManager;
        this.type = parsedJSONWidget.type;
        this.title = parsedJSONWidget.title;
        this.content = parsedJSONWidget.content;
        this.icon = parsedJSONWidget.icon || null;
        this.focusThisWidget = parsedJSONWidget.focusThisWidget || false;
        this.rotationX = parsedJSONWidget.rotationX;
        this.rotationY = parsedJSONWidget.rotationY;
        this.widgetGroup = parsedJSONWidget.widgetGroup || null;
        this.widgetButton = null;
        this.widgetButtonMesh = null;
        this.widgetTitleText = null;
        this.widgetManager = widgetManager;
    }

    createWidgetButton(){
        var iconURL = null;
        if(this.icon != null)
            iconURL = this.icon;
        else if(this.type == "video")
            iconURL = "./textures/Icon awesome-photo-video.svg";
        else if(this.type == "3d")
            iconURL = "./textures/Icon awesome-cube.svg";
        else if(this.type == "text")
            iconURL = "./textures/Icon open-text.svg";
        else if(this.type == "form")
            iconURL = "./textures/File.png";
        else if(this.type == "website")
            iconURL = "./textures/Icon awesome-link.svg";
        else if(this.type == "image")
            iconURL = "./textures/Icon awesome-image.svg";
        else if(this.type == "audio")
            iconURL = "./textures/Icon awesome-volume-up.svg";
        else if(this.type == "livedata")
            iconURL = "./textures/Icon awesome-chart-pie.svg";
        else if(this.type == "custom")
            iconURL = this.content;
        else
            iconURL = "./textures/Info.png";

        var button1 = BABYLON.GUI.Button.CreateSimpleButton("but1", "");
        button1.width = "900px"
        button1.height = "1000px";
        button1.background = getComputedStyle(document.documentElement).getPropertyValue('--color-faded-50');
        button1.cornerRadius = 200000;
        button1.paddingBottomInPixels = 270;
        button1.paddingTopInPixels = 250;
        button1.paddingLeftInPixels = 250;
        button1.paddingRightInPixels = 250;
        button1.clipChildren = false;
        button1.clipContent = false;
        button1.shadowBlur = 150;
        button1.thickness = 15;

        var button2 = BABYLON.GUI.Button.CreateImageOnlyButton("but1", iconURL);
        button2.width = "230px";
        button2.height = "250px";
        button2.color = "transparent";
        button1.addControl(button2);

        if(this.title){
            var text = new BABYLON.GUI.TextBlock("text",this.title);
            text.fontSizeInPixels = 130;
            text.color = "white";
            text.paddingTopInPixels = 730;
            text.widthInPixels = 900;
            text.clipContent = false;
            text.textWrapping = true;
            text.resizeToFit = true;
            text.shadowBlur = 35;
            text.scaleX = 0.7;
            text.isVisible = false;
            button1.addControl(text);
            this.widgetTitleText = text;
        }

        var buttonPlane = BABYLON.MeshBuilder.CreatePlane("card", {width:1.6, height:1.2}, this.scene);
        var buttonADT = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(buttonPlane);
        buttonADT.addControl(button1);
        this.widgetButtonMesh = buttonPlane;
        this.widgetButton = button1;

        this.setWidgetLocation();
    }

    setWidgetLocation(){
        this.widgetButtonMesh.position.z = 8;
        var widgetPivot = BABYLON.MeshBuilder.CreateSphere("sphere", {}, this.appManager.scene);
        widgetPivot.isVisible = false;
        widgetPivot.position = new BABYLON.Vector3(0, 0, 0);
        this.widgetButtonMesh.parent = widgetPivot;
        widgetPivot.rotation.x += Math.PI * (this.rotationX*0.1);
        widgetPivot.rotation.y += Math.PI * (this.rotationY*0.1);

        this.setUpAction();
    }

    setUpAction(){
        if(this.type != "custom"){
            this.widgetButton.onPointerClickObservable.add(fun => {
                if(this.type == "3d")
                    this.setUp3D();
                else if(this.type == "website")
                    window.open(this.content);
                else
                    new PanelGroup(this.widgetGroup, this.content, this.type, this.appManager);
            });
        }
        this.widgetButton.pointerEnterAnimation = () => {
            this.setWidgetScale("hover");
        }
        this.widgetButton.pointerOutAnimation = () => {
            this.setWidgetScale("unhover");
        }
        if(this.focusThisWidget){
            this.appManager.cameraManager.lookAtMeshSlowly(this.widgetButtonMesh);
        }
    }

    async setUp3D(){
        if(this.appManager.is3DModelRendered == false){
            this.appManager.is3DModelRendered = true;
            var newModel = await BABYLON.SceneLoader.ImportMeshAsync("", this.content);
            this.createCamera(newModel);
        }
    }

    createCamera(modelRef){
        this.appManager.camera.detachControl();
        var camera = new BABYLON.ArcRotateCamera("camera", 6, 1.3, 6, new BABYLON.Vector3(0,0,0), this.appManager.scene);
        camera.minZ = 0.01;
        camera.allowUpsideDown = false;
        camera.wheelPrecision = 150;
        camera.pinchDeltaPercentage = 0.0001;
        camera.useNaturalPinchZoom = false;
        camera.pinchPrecision = 0.0001;
        camera.panningSensibility = 0;
        camera.attachControl(document.getElementById("renderCanvas"), true);
        camera.rebuildAnglesAndRadius();
        camera.radius = 6;
        camera.useAutoRotationBehavior = true;
        camera.autoRotationBehavior.idleRotationSpeed = 0.1;
        camera.autoRotationBehavior.idleRotationWaitTime = 10;
        camera.autoRotationBehavior.idleRotationSpinupTime = 10;
        camera.autoRotationBehavior.zoomStopsAnimation = false;
        camera.lowerRadiusLimit = 1;
        camera.upperRadiusLimit = 10; 
        this.appManager.scene.activeCamera = camera;

        for (var i = 0; i < this.appManager.spaces[this.appManager.currentSpace].connections.length; i++) {
            this.appManager.spaces[this.appManager.currentSpace].connections[i].widgetButton.isVisible = false;
        }
        for (var i = 0; i < this.appManager.spaces[this.appManager.currentSpace].widgets.length; i++) {
            this.appManager.spaces[this.appManager.currentSpace].widgets[i].widgetButton.isVisible = false;
        }

        var button = document.createElement("button");
        button.setAttribute("id", "close3Dbutton");
        button.style.top = "5%";
        button.style.right = "5%";
        button.textContent = "close";
        button.style.width = "100px"
        button.style.height = "30px"
        button.style.position = "absolute";
        document.body.appendChild(button);
        
        var hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("https://charliebehan.github.io/DromoneARDemo/environment.dds", this.appManager.scene);
        this.appManager.scene.environmentTexture = hdrTexture;
        hdrTexture.gammaSpace = false;

        button.addEventListener("click", () => {
            for (var i = 0; i < this.appManager.spaces[this.appManager.currentSpace].connections.length; i++) {
                this.appManager.spaces[this.appManager.currentSpace].connections[i].widgetButton.isVisible = true;
            }
            for (var i = 0; i < this.appManager.spaces[this.appManager.currentSpace].widgets.length; i++) {
                this.appManager.spaces[this.appManager.currentSpace].widgets[i].widgetButton.isVisible = true;
            }
            this.appManager.scene.activeCamera = this.appManager.camera;
            this.appManager.scene.removeMesh(modelRef);
            modelRef.meshes[0].dispose();
            this.appManager.is3DModelRendered = false;
            camera.dispose();
            button.remove();
        });
    }

    setWidgetScale(value){
        if(value == "moving"){
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(0.4,0.4,0.4)
            this.setTitleTextVisibility(false);
        }
        else if(value == "hover" && this.widgetButtonMesh.scaling.x != 0.4){
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1.2,1.2,1.2)
            this.setTitleTextVisibility(true);
        }
        else if(value == "unhover" && this.widgetButtonMesh.scaling.x != 0.4){
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1,1,1)
            this.setTitleTextVisibility(false);
        }
        else if(value != "moving" && value != "hover" && value != "unhover"){
            this.widgetButtonMesh.scaling = new BABYLON.Vector3(1,1,1)
            this.setTitleTextVisibility(false);
        }
    }

    setTitleTextVisibility(isVisible){
        if(this.widgetTitleText)
            this.widgetTitleText.isVisible = isVisible;
    }

    dispose(){
        this.widgetButton.onPointerClickObservable.clear();
        this.widgetButtonMesh.dispose();
    }
}